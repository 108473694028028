import { RealEstateContainer } from '@client/components/Detail/Containers';
import {
  getDescription,
  getTitle,
} from '@client/components/SEO/DetailHeadTags';
import SocialMeta from '@client/components/SEO/DetailHeadTags/SocialMeta';
import Sitemap from '@client/components/SEO/Sitemap';
import {
  Marks,
  markPerformance,
} from '@client/utilities/performance/performanceMonitor';
import { fetchItemSuccess } from '@reducers/detail';
import { getStore } from '@reducers/store';
import { advScripts } from '@sbt-web/adv';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import {
  AdItem,
  FavoritesCounter,
  HTTPStatusCode,
  ItemCategory,
  type ProfileQueryData,
  type Shop,
  type TrustProfileInfo,
} from '@sbt-web/networking';
import { applyContextToFunc } from '@sbt-web/request-id-handler';
import getPlexusInternalLinks from '../server/detail-requests/plexus';
import {
  nullifyUndefinedProperties,
  readEnvironmentIdFromHeaders,
} from '@sbt-web/utils';
import { Theme } from '@shared/helpers/Themes';
import { getAdvertiserName } from '@shared/models/Advertiser';
import type { AdDetailQuery } from '@shared/routes';
import schemaOrg from '@shared/tools/seo/detail';
import type { Response } from 'express';
import type { NextPageContext } from 'next';
import Head from 'next/head';
import React, { useEffect, useState, useContext } from 'react';
import { fetchBackupItems, fetchItem } from '../server/detail-requests/item';
import {
  getAdvertiserProfile,
  getFavoriteCounter,
  getShopData,
  getTrustInfo,
  unwrap,
} from '../server/detail-requests/subito-services';
import { setStatusOnServer, type BasePageProps, GonePageProps } from './_app';
import type { AdDetailLinks } from '@client/components/SEO/InternalLinks/types';
//Carousel style:
import '@sbt-web/carousel/style';
import { getErrorMessage } from '@tools/errorHelpers';
import { useAdDetailCommons } from '@client/components/Detail/Containers/useAdDetailCommons';
import { SkeletonWithAdv } from '@client/components/Detail/Containers/SkeletonWithAdv';

interface AdDetailContext extends NextPageContext {
  query: AdDetailQuery;
  res: Response;
}

markPerformance(Marks.GLOBAL_RUN);

export type ImmobiliAdDetailPageProps = BasePageProps & {
  category: ItemCategory;
  internalLinks: AdDetailLinks | null;
  pageTitle: string;
  shop: Shop | null;
  favoriteCounter: FavoritesCounter | null;
  advertiserProfile: ProfileQueryData | null;
  trustInfo: TrustProfileInfo | null;
  promo: null;
  ad: AdItem;
  shippingCosts: null;
};

export default function AdDetail(props: ImmobiliAdDetailPageProps) {
  const {
    ad,
    pageTitle,
    internalLinks,
    shop,
    favoriteCounter,
    advertiserProfile,
    trustInfo,
  } = props;
  const categoryId = ad.category.id;
  const [newCreditAgricoleToggle, setNewCreditAgricoleToggle] = useState(false);
  const { optimizely } = useContext(OptimizelySubitoContext);

  useEffect(() => {
    optimizely?.onReady().then(() => {
      setNewCreditAgricoleToggle(
        optimizely.isFeatureEnabled('subito.web.adv.credit-agricole')
      );
    });
  }, [optimizely]);

  const advContextData = useAdDetailCommons({
    ad,
    trustPingEnabled: props.trustInfo?.presence.enabled,
  });

  return (
    <>
      <Head>
        <title key="page-title">{pageTitle}</title>
        <meta
          name="description"
          key="description"
          content={getDescription(ad)}
        />
        <meta
          name="robots"
          key="robots"
          content="index, follow, max-image-preview:large"
        />
        <link rel="canonical" key="canonical-url" href={ad.urls.default} />

        <script key="detail-schema" {...schemaOrg(ad)} />

        {SocialMeta({ adItem: ad })}

        {advScripts({
          config: {
            includeOw: true,
            includeTam: false,
            includeAdSense: true,
            includePrebid: false,
            owProfileID: '8848',
            publicPath: '/static/script',
          },
        })}
      </Head>

      <SkeletonWithAdv contextData={advContextData}>
        <RealEstateContainer
          ad={ad}
          categoryId={categoryId}
          internalLinks={internalLinks}
          shop={shop}
          favoriteCounter={favoriteCounter}
          advertiserProfile={advertiserProfile}
          trustInfo={trustInfo}
          newCreditAgricoleToggle={newCreditAgricoleToggle}
        />
      </SkeletonWithAdv>
      <Sitemap />
    </>
  );
}

const getProps = async (
  ctx: AdDetailContext
): Promise<
  | { props: ImmobiliAdDetailPageProps | GonePageProps }
  | { notFound: true }
  | { redirect: { destination: string; permanent: boolean } }
> => {
  const { query, req, res } = ctx;
  const { id } = query;

  if (!id) {
    return {
      notFound: true,
    };
  }

  let environmentId: string | undefined = undefined;

  try {
    if (req != null) {
      const eid = readEnvironmentIdFromHeaders(req);
      if (eid != null) {
        environmentId = eid;
      }
    }
  } catch (error) {
    console.error(
      'Could not set the Environment ID on the serverside,',
      getErrorMessage(error)
    );
  }

  const item = await fetchItem(id, environmentId, res);

  if (item == null) {
    const { backupItems, categoryDefaultUrl, category } =
      await fetchBackupItems(query.category);

    return {
      props: nullifyUndefinedProperties({
        ...setStatusOnServer(HTTPStatusCode.Gone, res),
        category: null,
        categoryLabel: category.label,
        categoryDefaultUrl,
        categoryId: category.id,
        items: backupItems,
        internalLinks: null,
        ad: null,
        shippingCosts: null,
        theme: Theme.Purple,
        pageName: 'addetail',
        pageTitle: 'Annuncio non trovato',
      }),
    };
  }

  // Augment the response:
  const [
    shopData,
    internalLinks,
    favoriteCounter,
    advertiserProfileResponse,
    trustInfoResponse,
  ] = await Promise.allSettled([
    getShopData(item.advertiser, res),
    getPlexusInternalLinks(
      {
        pageUrl: item.urls.default,
        category: item.category,
        geo: item.geo,
        baseQuery: `${item.subject} ${item.category.label} ${item.type.value.toLowerCase()} a ${item.geo.city?.value}`,
      },
      res
    ),
    getFavoriteCounter(item.urn, res),
    getAdvertiserProfile(item.advertiser.userId, res),
    getTrustInfo(item.advertiser, res),
  ]);

  const augmentResponse = {
    shop: unwrap(shopData),
    internalLinks: unwrap(internalLinks),
    favoriteCounter: unwrap(favoriteCounter),
    advertiserProfile: unwrap(advertiserProfileResponse),
    trustInfo: unwrap(trustInfoResponse),
  };

  // Cleanly set up the state as-is
  const store = getStore(undefined, true);

  store.dispatch(fetchItemSuccess(item));

  return {
    props: nullifyUndefinedProperties({
      ...augmentResponse,
      ...setStatusOnServer(HTTPStatusCode.OK, res),
      category: item.category,
      ad: item,
      shippingCosts: null,
      theme: Theme.Purple,
      promo: null,
      pageName: 'addetail',
      initialState: store.getState(),
      pageTitle: getTitle(
        item,
        getAdvertiserName(
          item.advertiser.type,
          augmentResponse.shop,
          augmentResponse.advertiserProfile
        )
      ),
    }),
  };
};

const wrappedGetProps = applyContextToFunc(getProps);

export { wrappedGetProps as getServerSideProps };
